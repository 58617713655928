import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../features/auth/authSlice'


const apiServerUrl = process.env.REACT_APP_ENDPOINT;

const baseQuery = fetchBaseQuery({
    baseUrl: apiServerUrl,
    credentials: 'include', // always send our cookies
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token

        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    //console.log(args) // request, url, method, body
    //console.log(api) // signal, dispatch, getState()
    //console.log(extraOptions) // custom like {key: value}

    let result = await baseQuery(args, api, extraOptions)
    //console.log("apiSlice:result="+JSON.stringify(result))

    // TODO: handle case where backend is down, user tries to login, frontend receives
    // {"error":{"status":"FETCH_ERROR","error":"TypeError: Failed to fetch"},"meta":{"request":{}}}
    // TODO: if you want, handle other status codes, too
    if (result?.error?.status === 403) {
        //console.log('sending refresh token')

        // send refresh token to get new access token 
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)
        //console.log("apiSlice.refreshResult="+JSON.stringify(refreshResult))
        if (refreshResult?.data) {
            //console.log("apiSlice.refreshResult.data="+JSON.stringify(refreshResult?.data));
            const user = api.getState().auth.user

            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data, user }))

            // retry original query with new access token
            result = await baseQuery(args, api, extraOptions)
        } else {
            // api.dispatch(logOut())
            if (refreshResult?.error?.status === 403) {
                refreshResult.error.data.message = "Your login has expired!"
            }
            return refreshResult
        }
    }

    return result
}


export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Contact', 'User', 'Company', 'RFP'],
    endpoints: builder => ({})
})