import React, {useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import usePersist from "../../hooks/usePersist";
import PulseLoader from 'react-spinners/PulseLoader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Typography, 
    Input, 
    Checkbox, 
    Button 
} from "@material-tailwind/react";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/solid";
import XConnectionsNavbarLogo from "../../assets/images/logos/XConnections_logo_navbar5b.png"

export function SignInPage() {

  const userRef = useRef(null);
  const errRef = useRef();

  const [username, setUsername] = useState("");
  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => setPasswordShown((cur) => !cur);

  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist();

  const navigate =  useNavigate();
  const dispatch = useDispatch();

  // API Login Mutation
  const [login, { isLoading }] = useLoginMutation()

  const showAlertMessage = (message) => {
    toast.error(message, {
      position: 'top-right',
    });
  };
    
  useEffect(() => {
    userRef.current?.focus()
  }, [userRef])

  useEffect(() => {
      setErrMsg('');
  }, [username, passValue.password])

  const handleSubmit = async (e) => {
      e.preventDefault();

      // Login from LoginMutation
      try {
        const userData = await login({username, ...passValue}).unwrap();

        dispatch(setCredentials({ ...userData, username }))
        setUsername("");
        setPassValue("");
        navigate('/dashboard/projects')
      } catch (err) {
          if (!err.status) {
              showAlertMessage('No server response');
          } else if (err.status === 400) {
              showAlertMessage('Missing Username or Password');
          } else if (err.status === 401) {
              showAlertMessage('Unauthorized! '+err?.data.message);
          } else if (err.status === 404) {
              showAlertMessage('Not Found');
          } else {
              console.log('error='+JSON.stringify(err));
              let message = err?.data?.message ? 'Login Failed' : 'Could not login, Please call customer support!'
              showAlertMessage(message);
          }
        errRef.current?.focus();
        console.error("ERROR"+JSON.stringify(err));
      }
    }

  const handleUserInput = ({ target }) => setUsername(target.value);
  const handlePasswordChange = (prop) => (event) => {
    setPassValue({ ...passValue, [prop]: event.target.value });
  };
  const handleToggle = () => setPersist(prev => !prev);

  const errClass = errMsg ? "errmsg" : "offscreen"

  if (isLoading) return <PulseLoader color="#123abc" size={20} />

  return (
    <section className="bg-xconnections-200">
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img src={XConnectionsNavbarLogo} alt='logo' />
        <span className='absolute right-0 bottom-0 h-3.5 w-3.5 rounded-full border-2 border-white bg-meta-3'></span>
      </a>
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Sign in to your account
          </h1>
          <form action="#" className="mx-auto max-w-[24rem] text-left">
            {/* start username section */}        
            <div className="mb-6">
              <label htmlFor="email">
                <Typography
                  variant="small"
                  className="mb-2 block font-medium text-gray-900"
                >
                  Your Username
                </Typography>
              </label>
              <Input
                id="username"
                label="username"
                color="gray"
                size="lg"
                type="text"
                //required
                name="username"
                placeholder="username"
                value={username}
                onChange={handleUserInput}
                labelProps={{
                  //className: "hidden",
                  //className: "before:content-none after:content-none",
                }}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              />            
            </div>
            {/* start password section */}        
            <div className="mb-6">
              <label htmlFor="password">
                <Typography
                  variant="small"
                  className="mb-2 block font-medium text-gray-900"
                >
                  Password
                </Typography>
              </label>
              <Input
                id="password"
                label="password"
                color="gray"
                size="lg"
                name="password"
                type={passwordShown ? "text" : "password"}
                placeholder="********"
                //required
                labelProps={{
                  className: "hidden",
                }}
                value={passValue.password}
                onChange={handlePasswordChange("password")}
                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                icon={
                  <i onClick={togglePasswordVisiblity}>
                    {passwordShown ? (
                      <EyeIcon className="h-5 w-5" />
                    ) : (
                      <EyeSlashIcon className="h-5 w-5" />
                    )}
                  </i>
                }
              />                  
              <Checkbox
                label={
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="flex font-medium items-center !text-gray-500"
                >
                  Remember me &nbsp;
                </Typography>
                }
                containerProps={{
                  className: "-ml-2.5",
                }}
                onChange={handleToggle}
                checked={persist}
              />
            </div>
            {/* start submit button */}
            <div className="mb-6"> 
              <Button 
                color={username && passValue.password ? "gray" : "blue-gray"}
                size="lg" 
                className="mt-6" 
                fullWidth
                disabled={!(username && passValue.password)}
                onClick={handleSubmit}
              >
                log in
              </Button>
              <Typography
                color="gray"
                className="mt-6 text-center font-normal"
              >
                <a href="forgot-password" className="underline font-medium text-gray-900">
                  Forgot password?{" "}
                </a>
              </Typography>
            </div>
          </form>
        </div>
      </div>
    </div>
    </section>
  );
}


export default SignInPage;