import {
  Card,
  CardBody,
  CardHeader,
  IconButton,
  Input,
  Textarea,
  Typography,
  Tooltip,
  Button,
  Switch,
} from "@material-tailwind/react";
import {
  MinusCircleIcon,
  ArchiveBoxIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import Avatar from '../../assets/images/users/avatar.jpeg';
import DefaultLayout from '../../layouts/DefaultLayout';
import Breadcrumb from '../../components/Breadcrumb';
import { useGetUserProfileQuery, useUpdateUserProfileMutation, useUpdateProfileImgMutation } from "../../features/users/userprofile";

import useAuth from "../../hooks/useAuth";



export function IcOutlineCancel(props) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m3.59-13L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z"></path></svg>);
}

export function HeroiconsOutlineSave(props) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7H5a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"></path></svg>);
}


export function Profile() {
  const [isEditing, setIsEditing] = useState(false);
  const { data: userProfileData, isLoading, isSuccess, error } = useGetUserProfileQuery();
  const [user, setUser] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userAbout, setUserAbout] = useState('');
  const [userLinkedin, setUserLinkedin] = useState('');
  const [emailUpdates, setEmailUpdates] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [profileImg, setProfileImgFile] = useState('');
  const [updateImage, setUpdateImage] = useState(false)

  const { username, roles, status, isVendor, isClient, isAdmin } = useAuth();

  const [photoURL, setPhotoURL] = useState(Avatar);

  const [updateUserProfile, {isUpdateLoading, isUpdateSucess, isUpdateError}] = useUpdateUserProfileMutation();

  useEffect(() => {
    if (isSuccess && userProfileData) {
      setUser(userProfileData?.uname || "");
      setUserMobile(userProfileData?.phone || "");
      setUserEmail(userProfileData?.email || "");
      setUserAbout(userProfileData?.about || "");
      setUserLinkedin(userProfileData?.linkedin || "");
      setEmailUpdates(userProfileData?.email_updates === "N" ? false : true);
      setFirstname(userProfileData?.fname || "");
      setLastname(userProfileData?.lname || "");
    }
  }, [isSuccess, userProfileData]);


  useEffect(() => {
    if (userProfileData?.img) {
      setPhotoURL('img/'+userProfileData.img);
    }
  }, [userProfileData])
  
  const handleEditClick = () => {
    setIsEditing(true);
  }

  const handleCancelClick = () => {
    setIsEditing(false);
  }

  const canSave = !isUpdateLoading;

  const handleSaveClick = async (e) => {
    e.preventDefault();
    if (canSave){
      setIsEditing(false);
      const rval = await updateUserProfile({about:userAbout, firstname, lastname, email:userEmail, phone:userMobile, linkedin:userLinkedin, emailupdates:emailUpdates});
    }
    
  }
  
  const handleEmailUpdatesToggle = () => {
    setEmailUpdates(!emailUpdates);
  }

  const handleFileChange = (e) => {
    if(e.target.files){
      setProfileImgFile(e.target.files[0]);
      setUpdateImage(true);
    }
  };

  const handleProfileImgCancel = (e) => {
    setProfileImgFile('');
    setUpdateImage(false);
  }

  const [addProfileImage, {imgLoading, isError, data}] = useUpdateProfileImgMutation();

  const handleUpload = async () => {
    if(profileImg){
      const new_document = new FormData();
      new_document.append("file" , profileImg);
      new_document.append("email", userEmail)
      new_document.append("fname", firstname)
      
      try{
        const result = await addProfileImage(new_document).unwrap();
        console.log("UpdateImage:result="+JSON.stringify(result));
      }
      catch (error){
        console.log(error);
      }
    }
    //handle later
  };

  const handleAboutChanged = ({target}) => setUserAbout(target.value);
  const handleFirstNameChanged = ({target}) => setFirstname(target.value);
  const handleLastNameChanged = ({target}) => setLastname(target.value);
  const handlePhoneChanged = ({target}) => setUserMobile(target.value);
  const handleLinkedInChanged = ({target}) => setUserLinkedin(target.value);
  const handleEmailUpdatesChanged = ({target})=> setEmailUpdates(target.value);

  return (
    <DefaultLayout>
      <Breadcrumb pageName='Profile' />
      <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-cover	bg-center">
        <div className="absolute inset-0 h-full w-full bg-gray-900/75" />
      </div>

      <Card className="mx-3 -mt-16 mb-6 lg:mx-4 border border-blue-gray-100">
        <CardBody className="p-4">
          <div className="gird-cols-1 mb-12 grid gap-12 px-4 lg:grid-cols-2 xl:grid-cols-2">
            <Card color="transparent" shadow={false}>
            <CardHeader
              color="transparent"
              shadow={false}
              floated={false}
              className="mx-0 mt-0 mb-4 flex items-center justify-between gap-4"
            >
              <Typography variant="h6" color="blue-gray">
                Profile Information
              </Typography>
              <Tooltip content="Edit Profile">
                  <PencilIcon className="h-4 w-4 cursor-pointer text-blue-gray-500" onClick={handleEditClick}/>
              </Tooltip>
            </CardHeader>
            <CardBody className="p-0">
              <Textarea value={userAbout} disabled={!isEditing} onChange={handleAboutChanged}>
              </Textarea>
                {/* start first name input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    First Name
                  </label>
                  <Input
                    label="first name"
                    icon={<i className="fa-regular fa-user" />}
                    value={firstname}
                    disabled={!isEditing}
                    onChange={handleFirstNameChanged}
                  />
                </div>
                <div className='mb-4'>
                </div>               
                {/* end first name input */}
                {/* start last name input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Last Name
                  </label>
                  <Input
                    label="last name"
                    icon={<i className="fa-regular fa-user" />}
                    type='text'
                    value={lastname}
                    disabled={!isEditing}
                    onChange={handleLastNameChanged}
                  />
                </div>
                <div className='mb-4'>
                </div>               
                {/* end last name input */}
                {/* start username input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    User Name
                  </label>
                  <Input
                    label="username"
                    icon={<i className="fa-regular fa-user" />}
                    type='text'
                    value={username}
                    disabled
                  />
                </div>
                <div className='mb-4'>
                </div>               
                {/* end username input */}
                {/* start mobile input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Mobile
                  </label>
                  <Input
                    label={userMobile}
                    icon={<i className="fa-solid fa-phone"></i>}
                    type='text'
                    disabled={!isEditing}
                    onChange={handlePhoneChanged}
                  />
                </div>
                <div className='mb-4'>
                </div>               
                {/* end mobile input */}
                {/* start email input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Email
                  </label>
                  <Input
                    label={userEmail}
                    icon={<i className="fa-regular fa-envelope"></i>}
                    type='text'
                    disabled
                  />
                  </div>
                  <div className='mb-4'>
                  </div>
                  {/* end email input */}
                  {/* start LinkedIn input */}
                  <div className="flex w-full flex-col gap-1">
                    <label className='mb-2.5 block font-medium text-black dark:text-white'>
                      LinkedIn
                    </label>
                    <Input
                      label={userLinkedin}
                      icon={<i className="fa-brands fa-linkedin"></i>}
                      type='text'
                      disabled={!isEditing}
                      onChange={handleLinkedInChanged}
                    />
                  </div>
                  <div className='mb-4'>
                  </div>
                  {/* end LinkedIn input */}
                  <Switch
                    key="emailUpdates"
                    id="emailUpdates"
                    label="Email Updates"
                    onChange={handleEmailUpdatesToggle}
                    checked={emailUpdates}
                    disabled={!isEditing}
                    labelProps={{
                      className: "text-sm font-normal text-blue-gray-500",
                    }}
                  />
                <div className="flex justify-center gap-4.5 py-4">
                {/* button section */}
                <div className="flex items-center justify-between">
                  <Button
                    className="flex items-center gap-2"
                    size="sm"
                    variant="text"
                    onClick={handleEditClick}
                  >
                    <PencilIcon className="h-4 w-4 text-gray-600" />
                    <Typography className="!font-semibold text-xs text-gray-600 md:block hidden">
                      Edit
                    </Typography>
                  </Button>
                  <Button
                    className="flex items-center gap-2"
                    size="sm"
                    variant="text"
                    color="orange"
                    onClick={handleCancelClick}
                  >
                    <MinusCircleIcon className="h-4 w-4 text-orange-500" />
                    <Typography className="!font-semibold text-xs text-orange-500 md:block hidden">
                      Cancel
                    </Typography>
                  </Button>
                  <Button
                    className="flex items-center gap-2"
                    size="sm"
                    variant="text"
                    color="green"
                    disabled={!isEditing}
                    onClick={handleSaveClick}
                  >
                    <ArchiveBoxIcon className="h-4 w-4 text-green-500" />
                    <Typography className="!font-semibold text-xs text-green-500 md:block hidden">
                      Save
                    </Typography>
                  </Button>
                </div>
              </div>
            </CardBody>
            </Card>
              <div className='rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark'>
              <div className='border-b border-stroke py-4 px-7 dark:border-strokedark'>
                <h3 className='font-medium text-black dark:text-white'>
                  Your Photo
                </h3>
              </div>
              <div className='p-7'>
                <form action='#'>
                  <div className='mb-4 flex items-center gap-3'>
                    <div className='h-14 w-14 rounded-full'>
                      <img src={photoURL} alt='Avatar' />
                    </div>
                    <div>
                      <span className='mb-1.5 text-black dark:text-white'>
                        Edit your photo
                      </span>
                      <span className='flex gap-2.5'>
                      <div className="flex items-center justify-between">
                        <Button
                          size="sm"
                          variant="text"
                          className="flex items-center gap-2"
                        >
                          <PencilIcon className="h-4 w-4 text-gray-600" />
                          <Typography className="!font-semibold text-xs text-gray-600 md:block hidden">
                            Edit
                          </Typography>
                        </Button>
                        <Button
                          size="sm"
                          variant="text"
                          color="red"
                          className="flex items-center gap-2"
                        >
                          <TrashIcon className="h-4 w-4 text-red-500" />
                          <Typography className="!font-semibold text-xs text-red-500 md:block hidden">
                            Delete
                          </Typography>
                        </Button>
                      </div>
                      </span>
                    </div>
                  </div>

                  <div
                    id='FileUpload'
                    className='relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5'
                  >
                    {/*<Input
                      type="file"
                      variant="outlined"
                      label="Upload File"
                      value=""
                      size="lg"
                      className="!border-stroke dark:!border-strokedark"
                    />*/}
                    <input
                      type='file'
                      onChange={handleFileChange}
                      accept='image/*'
                      className='absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none'
                    />
                    <div className='flex flex-col items-center justify-center space-y-3'>
                      <span className='flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark'>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z'
                            fill='#3C50E0'
                          />
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z'
                            fill='#3C50E0'
                          />
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z'
                            fill='#3C50E0'
                          />
                        </svg>
                      </span>
                      <p>
                        <span className='text-primary'>Click to upload</span> or
                        drag and drop to update your photo
                      </p>
                      <p className='mt-1.5'>SVG, PNG, JPG or GIF</p>
                      <p>(max, 800 X 800px)</p>
                    </div>
                  </div>

                  <div className='flex justify-end gap-4.5'>
                    <Button
                      className="flex items-center gap-2"
                      size="sm"
                      variant="text"
                      color="orange"
                      disabled={!updateImage}
                      onClick={handleProfileImgCancel}
                    >
                      <MinusCircleIcon className="h-4 w-4 text-orange-500" />
                      <Typography className="!font-semibold text-xs text-orange-500 md:block hidden">
                        Cancel
                      </Typography>
                    </Button>
                    <Button
                      className="flex items-center gap-2"
                      size="sm"
                      variant="text"
                      color="green"
                      onClick={handleUpload}
                      disabled={!updateImage}
                    >
                      <ArchiveBoxIcon className="h-4 w-4 text-green-500" />
                      <Typography className="!font-semibold text-xs text-green-500 md:block hidden">
                        Save
                      </Typography>
                    </Button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </CardBody>
      </Card>
    </DefaultLayout>
  );
}

export default Profile;