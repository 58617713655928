import {
    createSelector,
    createSlice,
    createAsyncThunk,
    current,
    isRejectedWithValue,
    nanoid,
    original,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice"

const companiesAdapter = createEntityAdapter({
    // assume IDs are stored in a field other than `company.id`
    // TODO: should this be => id
    selectId: (company) => company.id,
    // keep the "all IDs" array sorted based on company name
    // TODO: should this be a.companyname AND b.companyname
    sortComparer: (a, b) => a.name.localeCompare(b.name)
})

const companiesSlice = createSlice({
    name: 'companies',
    initialState: companiesAdapter.getInitialState({
        loading: 'idle',
    }),
    reducers: {
        // Can pass adapter functions directly as case reducers.  Because we're passing this
        // as a value, `createSlice` will auto-generate the `companyAdded` action type / creator
        companyAdded: companiesAdapter.addOne,
        companyUpdated: companiesAdapter.updateOne,
        companyDeleted: companiesAdapter.removeOne,
        companyReset: companiesAdapter.removeAll,
        // companyReceived: companiesAdapter.setAll
        companyLoading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending'
            }
        },
        companyReceived(state, action) {
            // Or, call them as "mutating" helpers in a case reducer
            // companiesAdapter.removeAll(state)
            // companiesAdapter.setAll(state, action.payload)
            if (state.loading === 'pending') {
                companiesAdapter.setAll(state, action.payload.companies)
                state.loading = 'succeeded'
            }
        },
        companyRequest(state, action) {
            // Can update the additional state field
            state.loading = 'pending'
        },
        companySuccess(state, action) {
            state.loading = 'succeeded'
        },
        companyFailure(state, action) {
            state.loading = 'failed'
        }
    }
})

export default companiesSlice