import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    // we expect to receive a token from the api response
    initialState: { 
        isLoggedIn: false,
        isAuthenticated: false,
        isRegistered: false,
        user: null, 
        token: null,
        company: null,
    },
    reducers: {
        setCredentials: {
            reducer: (state = authSlice.initialState, action) => {
              // state.push(action.payload)
              const { accessToken, refreshToken, roles, company, username } = action.payload
              state.user = username
              state.token = accessToken
              state.company = company
              // NOTE: action.payload only includes acccessToken, it does not have username - isLoggedIn will be false
              username || accessToken ? state.isLoggedIn = true : state.isLoggedIn = false
            },
        },
        logOut: (state, action) => {
            state.isLoggedIn = false
            state.user = null
            state.token = null
            state.company = null
        },
    }
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentCompany = (state) => state.auth.company