import { Checkbox } from "@material-tailwind/react";

const TABLE_HEAD = [
    {
      head: "Step ID",
      icon: <Checkbox />,
    },
    {
      head: "Step", 
    },
    {
      head: "Status",
    },
    {
      head: "",
    },
  ];

export default TABLE_HEAD;