import React, { useState } from "react";
//import { Dialog, DialogTitle, Listbox } from "@headlessui/react"
//import { Radio, RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import {
  Input,
  Option,
  Radio,
  Select,
  Button,
  Dialog,
  Textarea,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAddRfpMutation } from "../../features/rfp/rfpApi.js"
import { rfpActions } from "../../features/rfp/rfpSlice.js"
import { useDispatch } from 'react-redux';

export function AddRFPDialog() {

  const [addRfp, { isLoading, isError, isSuccess }] = useAddRfpMutation();

  const projects = [
    { exists: "no" },
    { exists: "yes" }
  ]

  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [budget, setBudget] = useState("");
  const [preferredTechnology, setPreferredTechnology] = useState("");
  const [existingProject, setExistingProject] = useState("");
  //const [existingProject, setExistingProject] = useState(projects[0]);
  const [status, setStatus] = useState("");
  const [domain, setDomain] = useState("");
  //const [index, setIndex] = useState("");


  const onDescriptionChanged = e => setDescription(e.target.value)
  const onDurationChanged = e => setDuration(e.target.value)
  const onBudgetChanged = e => setBudget(e.target.value)
  const onPreferredTechnologyChanged = e => setPreferredTechnology(e.target.value)
  //const onExistingProjectChanged = e => setExistingProject(e.target.value)
  //const onStatusChanged = e => setStatus(e.target.value)
  const onStatusChanged = (e) => {
    console.log("event=", e)
    //setStatus("new-rfp")
    setStatus(e.target.value)
    console.log("status=", status)
  }
  const onDomainChanged = e => setDomain(e.target.value)

  const [open, setOpen] = React.useState(false);
 
  const handleOpen = () => setOpen(!open);

  // Accessing the dispatch function from react-redux
  const dispatch = useDispatch();

  //const handleAddTask = () => {
  const handleAddTask = async (event) => {
    event.preventDefault();
    console.log("handleAddTask was called...");
    console.log("description=", description)
    console.log("budget=", budget)
    console.log("duration=", duration)
    console.log("preferredTechnology=", preferredTechnology)
    console.log("existingProject=", existingProject)
    console.log("status=", status)
    console.log("domain=", domain)
    // Update reducer state rfpAdded
    try {
        // Create RFP Card
        // Submit data entered into DB        
        //let id: 100
        let newRfp = {
            id: 0,
            description, 
            duration: parseInt(duration), 
            budget: parseFloat(budget), 
            preferredTechnology, 
            existingProject, 
            status, 
            domain 
        };
        const result = await addRfp(newRfp).unwrap();
        // if the API call is successful, update the local state
        dispatch(rfpActions.rfpAdded({
            //result
            id: 100, 
            description: description, // 'temp rfp',
            duration: duration, // 10
            budget: budget, // 1000,
            preferredTechnology: preferredTechnology, // 'rust',
            existingProject: existingProject, // 'no',
            status: status, // 'new-rfp',
            domain: domain, // 'finance',
            //index: 0
        }))
    } catch (error) {
        console.error('Failed to add RFP:', error);
    }

    // if adding rfp was successful, close dialog
    setOpen(!open) // close modal
  }
  
  return (
    <>
      <button 
        //className="ml-auto bg-xconnections-200"
        className="heading-md mr-[16px] h-[32px] w-[48px] rounded-full bg-xconnections-200 text-white hover:bg-xconnections-500 sm:mr-[24px] sm:h-[48px] sm:w-[164px]"
        onClick={handleOpen} 
        //variant="gradient"
      >
        + Add RFP
      </button>
      <Dialog size="sm" open={open} handler={handleOpen} className="p-4">
        <DialogHeader className="relative m-0 block">
          <Typography variant="h4" color="blue-gray">
            Add New RFP
          </Typography>
          <Typography className="mt-1 font-normal text-gray-600">
            Request for Proposal
          </Typography>
          <IconButton
            size="sm"
            variant="text"
            className="!absolute right-3.5 top-3.5"
            onClick={handleOpen}
          >
            <XMarkIcon className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody className="space-y-4 pb-6">
          <div>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 text-left font-medium"
            >
              Preferred Technology
            </Typography>
            <Input
              color="gray"
              size="lg"
              placeholder="eg. React, Python, Rust"
              name="name"
              className="placeholder:opacity-100 focus:!border-t-gray-900"
              containerProps={{
                className: "!min-w-full",
              }}
              labelProps={{
                className: "hidden",
              }}
              defaultValue={preferredTechnology}
              onChange={onPreferredTechnologyChanged}
            />
          </div>
          <div>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 text-left font-medium"
            >
              Domain
            </Typography>
            <Select
              className="!w-full !border-[1.5px] !border-blue-gray-200/90 !border-t-blue-gray-200/90 bg-white text-gray-800 ring-4 ring-transparent placeholder:text-gray-600 focus:!border-primary focus:!border-t-blue-gray-900 group-hover:!border-primary"
              placeholder="1"
              labelProps={{
                className: "hidden",
              }}
              //value={domain}
              //onChange={(val) => setDomain(val)}              
            >
              <Option value='HealthCare' onClick={() => setDomain('HealthCare')}>HealthCare</Option>
              <Option value='IT' onClick={() => setDomain('IT')}>IT</Option>
              <Option value='Finance' onClick={() => setDomain('Finance')}>Finance</Option>
            </Select>
          </div>
          <div className="flex gap-4">
            <div className="w-full">
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-2 text-left font-medium"
              >
                Duration
              </Typography>
              <Input
                color="gray"
                size="lg"
                placeholder="eg. <11 weeks | 11 weeks"
                name="weight"
                className="placeholder:opacity-100 focus:!border-t-gray-900"
                containerProps={{
                  className: "!min-w-full",
                }}
                labelProps={{
                  className: "hidden",
                }}
                defaultValue={duration}
                onChange={onDurationChanged}
              />
            </div>
            <div className="w-full">
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-2 text-left font-medium"
              >
                Budget
              </Typography>
              <Input
                color="gray"
                size="lg"
                placeholder="eg. USD 10,000"
                name="size"
                className="placeholder:opacity-100 focus:!border-t-gray-900"
                containerProps={{
                  className: "!min-w-full",
                }}
                labelProps={{
                  className: "hidden",
                }}
                defaultValue={budget}
                onChange={onBudgetChanged}
              />
            </div>
          </div>
          <div>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 text-left font-medium"
            >
              Description (Optional)
            </Typography>
            <Textarea
              rows={7}
              placeholder="eg. Request to build new AI platform."
              className="!w-full !border-[1.5px] !border-blue-gray-200/90 !border-t-blue-gray-200/90 bg-white text-gray-600 ring-4 ring-transparent focus:!border-primary focus:!border-t-blue-gray-900 group-hover:!border-primary"
              labelProps={{
                className: "hidden",
              }}
              defaultValue={description} 
              onChange={onDescriptionChanged}
            />
          </div>
          <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 text-left font-medium"
            >
              Existing Project ?
          </Typography>          
          <div className="flex gap-10">
            <Radio 
              name="type" 
              label="yes" 
              ripple={true} 
              //defaultValue={existingProject}
              //value={existingProject}
              //onChange={setExistingProject}
              value="yes"
              onClick={() => setExistingProject('yes')}
              //checked={existingProject === choice.value}
              //onChange={(e) => setExistingProject(e.target.value)}              
            />
            <Radio name="type" label="no" ripple={true} value="no" onClick={() => setExistingProject('no')} defaultChecked />
            {/*<Radio name="type" label="no" ripple={true} defaultValue={existingProject} onChange={setExistingProject} defaultChecked />*/}
          </div>
          {/*
          <div className="w-full px-4">
            <div className="mx-auto w-full max-w-md">
              <RadioGroup by="name" value={existingProject} onChange={setExistingProject} aria-label="Server size" className="space-y-2">
                {projects.map((project) => (
                  <Radio
                    key={project.exists}
                    value={project}
                    className="group relative flex cursor-pointer rounded-lg bg-black/5 py-4 px-5 text-black shadow-md transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-black data-[checked]:bg-black/10"
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="text-sm/6">
                        <p className="font-semibold text-black">{project.exists}</p>
                        <div className="flex gap-2 text-black/50">
                        </div>
                      </div>
                      <CheckCircleIcon className="size-6 fill-black opacity-0 transition group-data-[checked]:opacity-100" />
                    </div>
                  </Radio>
                ))}
              </RadioGroup>
            </div>
          </div>
          */}
          <div>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 text-left font-medium"
            >
              Status
            </Typography>
            <Select
              className="!w-full !border-[1.5px] !border-blue-gray-200/90 !border-t-blue-gray-200/90 bg-white text-gray-800 ring-4 ring-transparent placeholder:text-gray-600 focus:!border-primary focus:!border-t-blue-gray-900 group-hover:!border-primary"
              placeholder="1"
              label="select status"
              labelProps={{
                className: "hidden",
              }}
              //value={status}
              //onChange={(val) => setStatus(val)}      
              //labelProps={{
              //  className: "text-sm font-normal text-blue-gray-500",
              //}}
              //defaultValue="new-rfp"
              //defaultValue={status}
              //onChange={onStatusChanged}
            >
              <Option
                value='new-rfp' 
                onClick={() => setStatus('new-rfp')}
              >
                New RFP
              </Option>
              <Option
                value='requirement-analysis' 
                onClick={() => setStatus('requirement-analysis')}
              >
                Requirement Analysis
              </Option>
              <Option
                value='vendor-notification' 
                onClick={() => setStatus('vendor-notification')}
              >
                Vendor Notification
              </Option>
            </Select>
          </div>          
        </DialogBody>
        <DialogFooter>
          <Button 
            className="ml-auto bg-xconnections-200"
            onClick={handleAddTask}
          >
            Add RFP
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}