import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";
import { HeartIcon } from "@heroicons/react/24/solid";

import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";



export function Footer({ brandName, brandLink, routes }) {
  const year = new Date().getFullYear();

  const { username, status } = useAuth();

  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <footer className="py-2">
      <div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 md:justify-between">
        <Typography variant="small" className="font-normal text-inherit">
          &copy; {year}, made with{" "}
          <HeartIcon className="-mt-0.5 inline-block h-3.5 w-3.5 text-red-600" /> by{" "}
          <a
            href={brandLink}
            rel="noreferrer" 
            target="_blank"
            className="transition-colors hover:text-blue-500 font-bold"
          >
            {brandName}
          </a>{" "}
          for a better network.
        </Typography>
        <ul className="flex items-center gap-4">
          {routes.map(({ name, path }) => (
            <li key={name}>
              <Typography
                as="a"
                href={path}
                target="_blank"
                variant="small"
                className="py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
              >
                {name}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  brandName: "XConnections",
  brandLink: "https://www.xconnections.co",
  routes: [
    { name: "XConnections", path: "https://www.xconnections.co" },
    { name: "About Us", path: "https://www.xconnections.co/readme" },
    // { name: "Blog", path: "https://www.xconnections.co/blog" },
    { name: "License", path: "https://www.xconnections.co/license" },
  ],
};

Footer.propTypes = {
  brandName: PropTypes.string,
  brandLink: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

Footer.displayName = "/src/widgets/layout/footer.jsx";

export default Footer;