import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice"

const rfpAdapter = createEntityAdapter({})

const initialState = rfpAdapter.getInitialState()

export const rfpApiSlice = apiSlice.injectEndpoints({
    tagTyes: ['RFP'],
    endpoints: builder => ({
        getRfps: builder.query({
            query: () => '/client/getrfps',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: response => {
                return response
            },
            //transformResponse: (response) => {
            //    return [{
            //        id: response.id,
            //        duration: response.duration,
            //        description: response.description,
            //    }]
            //},
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => response.status,
            // Provide the tag/s to your endpoint to listen to, so in case they change, the cache will be invalidated for that endpoint.
            //providesTags: ['RFP'],
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Rfp', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Rfp', id }))
                    ]
                } else return [{ type: 'Rfp', id: 'LIST' }]
            },
            //providesTags: (result, error, id) => [{ type: 'RFP', id }],
            // This part is the trigger point for the tag invalidation (cache invalidation).
            // Once this is attached to a mutation endpoint, the listeners (endpoints) 
            // for this tag will experience cache invalidation for their data, which will trigger another fetch call.
            //invalidateTags: ['RFP'],
        }),
        getRfp: builder.query({
            query: (id) => `/client/rfp/${id}`,
            transformResponse: response => {
                return response
            },
            providesTags: (result, error, id) => [{ type: 'Rfp', id }],
        }),
        addNewRfp: builder.mutation({
            query: initialRfpData => ({
                url: '/client/rfps',
                method: 'POST',
                body: {
                    ...initialRfpData,
                }
            }),
            transformResponse: (response, meta, arg) => {
                return arg;
            },
            invalidatesTags: [
                { type: 'Rfp', id: "LIST" }
            ]
        }),
        deleteRfp: builder.mutation({
            query: ({ id }) => ({
                url: `/client/rfps/${id}`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Rfp', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetRfpsQuery,
    useLazyGetRfpsQuery,
    useGetRfpQuery,
    useAddNewRfpMutation,
    useDeleteRfpMutation,
} = rfpApiSlice

// returns the query result object
export const selectRfpsResult = rfpApiSlice.endpoints.getRfps.select()

// creates memoized selector
const selectRfpsData = createSelector(
    selectRfpsResult,
    rfpsResult => rfpsResult.data // normalized state object with ids & entities
)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllRfps,
    selectById: selectRfpById,
    selectIds: selectRfpIds
    // Pass in a selector that returns the notes slice of state
} = rfpAdapter.getSelectors(state => selectRfpsData(state) ?? initialState)
