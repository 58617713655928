import { configureStore } from "@reduxjs/toolkit"
import { composeWithDevTools, composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { apiSlice } from '../api/apiSlice'
import { setupListeners } from "@reduxjs/toolkit/query"
import authReducer from '../features/auth/authSlice'
import companiesSlice from "../features/companies/companySlice"
import { usersApiSlice } from "../features/users/usersApiSlice"
import { employeesApiSlice } from "../features/employees/employeesApiSlice"
// TODO: should be features/rfp/rfpSlice
//import { rfpReducer } from "../features/client/rfpApiSlice"
import { rfpReducer } from "../features/rfp/rfpSlice"
import { rfpApi } from '../features/rfp/rfpApi';

const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  // Specify here name, actionsDenylist, actionsCreators and other options
});



// The store is created by passing in a reducer, and has a method called getState that 
// returns the current state value
export const store = configureStore({
    reducer: {
        // Add the generated reducers as a specific top-level slice
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        rfp: rfpReducer,
        [rfpApi.reducerPath]: rfpApi.reducer,
        [companiesSlice.reducerPath]: companiesSlice.reducer,
        //companies: companiesSlice.reducer,
        //users: usersApiSlice.reducer,
        //employees: employeesApiSlice.reducer
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware,  rfpApi.middleware),
    devTools: true
})

console.log(store.getState().companies)

// The Redux store has a method called dispatch.
// The only way to update the state is to call store.dispatch()
// and pass in an action object.
// The store will run its reducer function and save the new state value inside,
// and we can call getState() to retrieve the updated value.
// You can think of dispatching actions as "triggering an event" in the application. 
// Something happened, and we want the store to know about it. 
// Reducers act like event listeners, and when they hear an action they are interested in, they update the state in response.

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors,
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)