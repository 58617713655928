import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice"

const rfpStepsAdapter = createEntityAdapter({})

const initialState = rfpStepsAdapter.getInitialState()

export const rfpStepsApiSlice = apiSlice.injectEndpoints({
    tagTyes: ['RFPSteps'],
    endpoints: builder => ({
        getRfpSteps: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: (id) => ({
                url: `/client/rfp_steps/${id}`,
                //header: {'content-type': 'application/json', 
                //    'Access-Control-Allow-Origin': '*', 
                //    'Access-Control-Allow-Credentials': "true"
                //},
                validateStatus: (response, result) => {
                    return response.status === 200
                },
            }),
            // Keep unused data for 5 seconds - cache in RTK query - default is 60 seconds
            keepUnusedDataFor: 5,
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: response => {
                 return response;
            },
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => response.status,
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'ProjectSteps', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'ProjectSteps', id }))
                    ]
                } else return [{ type: 'ProjectSteps', id: 'LIST' }]
            }
        }),        
        getRfpStepById : builder.query({
            query: ({ rfp_id, step_id }) => ({
                url: `/client/rfp_step/${rfp_id}/${step_id}`
            }),
            transformResponse: response => {
                return response;
           },
        }),
    }),
})

export const {
    useGetRfpStepsQuery,
    useLazyGetRfpStepsQuery,
    useGetRfpStepByIdQuery,
} = rfpStepsApiSlice

// returns the query result object
export const selectRfpStepsResult = rfpStepsApiSlice.endpoints.getRfpSteps.select()

// creates memoized selector
const selectRfpStepsData = createSelector(
    selectRfpStepsResult,
    rfpStepsResult => rfpStepsResult.data // normalized state object with ids & entities
)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllRfpSteps,
    selectById: selectRfpStepsById,
    // Pass in a selector that returns the notes slice of state
} = rfpStepsAdapter.getSelectors(state => selectRfpStepsData(state) ?? initialState)


// The object created by createApi also returns a "lazy" version
// of each query:
export const { useLazyAttemptRfpStepsQuery } = rfpStepsApiSlice