import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const rfpApi = createApi({
  reducerPath: 'rfpApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_ENDPOINT }),
  endpoints: (builder) => ({
    addRfp: builder.mutation({
      query: (rfp) => (
        console.log("rfp="+JSON.stringify(rfp)),
      {
        url: '/client/rfps',
        method: 'POST',
        body: rfp,
      }),
    }),
    updateRfp: builder.mutation({
      query: ({id, status}) => (
        console.log("id="+JSON.stringify(id)),
        console.log("status="+JSON.stringify(status)),
      {
        url: `/client/rfp/${id}`,
        method: 'PATCH',
        body: {
            status: status
        },
      }),
    }),
    /*
    updateRfp: builder.mutation({
      query: ({ id, ...initialRfpData }) => (
        console.log("id="+JSON.stringify(id)),
        console.log("initialRfpData",initialRfpData),
        console.log("initialRfpData",initialRfpData.status),
        console.log("initialRfpData="+JSON.stringify(initialRfpData)),
      {
        url: `/client/rfp/${id}`,
        method: 'PATCH',
        body: {
          //status: initialRfpData, // newStatus:"requirement-analysis"
          ...initialRfpData,
        }
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Rfp', id: arg.id }
      ]
    }),
    */
  }),
});

export const { useAddRfpMutation, useUpdateRfpMutation } = rfpApi;