import { apiSlice } from "../../api/apiSlice"
import { logOut, setCredentials } from "./authSlice"

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            // passing in username and password
            query: credentials => ({
                url: '/auth/login',
                method: 'POST',
                body: { ...credentials }
            }),
            async onQuery(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    const { accessToken } = data
                    dispatch(setCredentials({ accessToken }))
                    //console.log("authApiSlice.login.data="+JSON.stringify(data));
                } catch (err) {
                    console.log("ERROR: authApiSlice.login.error="+JSON.stringify(err));
                }
            }
        }),
        sendLogout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                credentials: 'include',                
                method: 'POST',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // should get response from backend - cookie cleared
                    // console.log("authApiSlice.logout.data="+JSON.stringify(data));
                    // set token to null
                    dispatch(logOut())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (err) {
                    console.log("ERROR: authApiSlice.logout.error="+JSON.stringify(err));
                }
            }
        }),
        refresh: builder.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'GET',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    const { accessToken } = data
                    dispatch(setCredentials({ accessToken }))
                } catch (err) {
                    console.error("ERROR: authApiSlice.refresh.error="+JSON.stringify(err));
                }
            }            
        }),
        registerUser: builder.mutation({
            query: initialUser => ({
                url: '/auth/register',
                method: 'POST',
                body: {
                    ...initialUser,
                }
            }),
            transformResponse: response => {
                return response;
            },
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        activateUser: builder.mutation({
            query: ({ ...userData }) => (
                {
                    // url: `/auth/activation/user/${userData.hashId}?email=${userData.email}&username=${userData.username}`,
                    // url: `/auth/activation/user/${userData.hashId}`,
                    url: `/auth/activation/user`,
                    method: 'POST',
                    body: { 
                        ...userData,
                    }
                }
            ),
            transformResponse: response => {
                return response;
            },
            // Pick out data and prevent nested properties in a hook or selector
            // transformResponse: (response, meta, arg) => response.data,
            // Pick out errors and prevent nested properties in a hook or selector
            // transformErrorResponse: (response, meta, arg) => response.status,
            invalidatesTags: (result, error, arg) => [
                { type: 'User' }
            ],
            // onQueryStarted is useful for optimistic updates
            // The 2nd parameter is the destructured `MutationLifecycleApi`
            async onQueryStarted(
                arg,
                { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
            ) {},
            // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                dispatch,
                getState,
                extra,
                requestId,
                cacheEntryRemoved,
                cacheDataLoaded,
                getCacheEntry,
                }
            ) {},
        }),
        getActivationLink: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: hashId => ({
                url: `/auth/activation/user/${hashId}`,
                header: {'content-type': 'application/json', 
                    'Access-Control-Allow-Origin': '*', 
                    'Access-Control-Allow-Credentials': "true"
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
                providesTags: (result, error, id) => [{ type: 'Link', hashId }],
            }),
            // Keep unused data for 30 seconds - cache in RTK query - default is 60 seconds
            keepUnusedDataFor: 30,
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: response => {
                 return response;
            },
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => response.status,
        }),
        forgotPasswordUser: builder.mutation({
            query: ({ ...userData }) => (
                {
                    url: `/auth/forgot-password/email=${userData.email}`,
                    method: 'POST',
                    body: { 
                        ...userData,
                    }
                }
            ),
            transformResponse: response => {
                return response;
            },
            // Pick out data and prevent nested properties in a hook or selector
            // transformResponse: (response, meta, arg) => response.data,
            // Pick out errors and prevent nested properties in a hook or selector
            // transformErrorResponse: (response, meta, arg) => response.status,
            invalidatesTags: (result, error, arg) => [
                { type: 'User' }
            ],
            // onQueryStarted is useful for optimistic updates
            // The 2nd parameter is the destructured `MutationLifecycleApi`
            async onQueryStarted(
                arg,
                { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
            ) {},
            // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                dispatch,
                getState,
                extra,
                requestId,
                cacheEntryRemoved,
                cacheDataLoaded,
                getCacheEntry,
                }
            ) {},
        }),
        // The mutation accepts a `Partial<Post>` arg, and returns a `Post`
        resetPasswordUser: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: ({ password, token, userId, ...userData }) => (
                {
                    url: `/auth/reset-password/?newPassword=${userData.newPassword}&token=${userData.userToken}&userId=${userId}`,
                    method: 'POST',
                    body: { 
                        ...userData,
                    }
                }
            ),
            //providesTags: (result, error, id) => [{ type: 'Reset', id }],
            // Pick out data and prevent nested properties in a hook or selector
            //transformResponse: (response, meta, arg) => response.data,
            transformResponse: response => {
                return response;
            },
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => response.status,
            // Pick out data and prevent nested properties in a hook or selector
            // transformResponse: (response, meta, arg) => response.data,
            // Pick out errors and prevent nested properties in a hook or selector
            // transformErrorResponse: (response, meta, arg) => response.status,
            invalidatesTags: (result, error, arg) => [
                { type: 'User' }
            ],
            // onQueryStarted is useful for optimistic updates
            // The 2nd parameter is the destructured `MutationLifecycleApi`
            async onQueryStarted(
                arg,
                { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
            ) {},
            // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                dispatch,
                getState,
                extra,
                requestId,
                cacheEntryRemoved,
                cacheDataLoaded,
                getCacheEntry,
                }
            ) {},
        }),
    })
})

export const {
    useLoginMutation,
    useSendLogoutMutation,
    useRefreshMutation,
    useRegisterUserMutation,
    useActivateUserMutation,
    useGetActivationLinkQuery,
    useForgotPasswordUserMutation,
    useResetPasswordUserMutation
} = authApiSlice 