import React from 'react';
import type { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, CardContent, CardHeader } from "../kanban/ui/card.tsx";
import { Button } from "../kanban/ui/button.tsx";
import { cva } from "class-variance-authority";
import { GripVertical } from "lucide-react";
import { Badge } from "../kanban/ui/badge.tsx";
import { ColumnId } from "./KanbanBoard.tsx";

/*
export interface Task {
  id: UniqueIdentifier;
  columnId: ColumnId;
  content: string;
  status: string;
}
*/

//id: "proposal1",
//columnId: "new-rfp",
//content: "Build AI Application",
//status: "new",

export interface Task {
    id: UniqueIdentifier;
    description: string;
    duration: string;
    budget: number;
    preferredTechnology: string;
    existingProject: string;
    status: string;
    domain: string;
    //index: UniqueIdentifier;
    sequence: number;
}

interface TaskCardProps {
  task: Task;
  isOverlay?: boolean;
}

export type TaskType = "Task";

export interface TaskDragData {
  type: TaskType;
  task: Task;
}

export function TaskCard({ task, isOverlay }: TaskCardProps) {
  //console.log("task", task) // object budget: 10000, description: "Build AI Application"
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: "Task",
      task,
    } satisfies TaskDragData,
    attributes: {
      roleDescription: "Task",
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva("", {
    variants: {
      dragging: {
        over: "ring-2 opacity-30",
        overlay: "ring-2 ring-primary",
      },
    },
  });

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
      })}
    >
      <CardHeader className="bg-xconnections-500 rounded-t-xl px-3 py-3 space-between flex flex-row border-b-2 border-secondary relative">
        <Button
          variant={"ghost"}
          {...attributes}
          {...listeners}
          className="bg-columnBackgroundColor p-1 text-secondary-foreground/50 -ml-2 h-auto cursor-grab"
        >
          <span className="sr-only">Move task</span>
          <GripVertical />
        </Button>
        <Badge variant={"outline"} className="ml-auto bg-white font-semibold">
          {task.duration}
        </Badge>
      </CardHeader>
      <CardContent className="bg-xconnections-400 rounded-b-xl px-3 pt-3 pb-6 text-left whitespace-pre-wrap">
        {task.description}
      </CardContent>
    </Card>
  );
}
