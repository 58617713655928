import { useMemo, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { createPortal } from "react-dom";

import DefaultLayout from '../../layouts/DefaultLayout';
import Breadcrumb from '../../components/Breadcrumb';

import PulseLoader from 'react-spinners/PulseLoader';
import ErrorsPage from '../../pages/auth/ErrorPage.jsx';

import { BoardColumn, BoardContainer } from "./BoardColumn.tsx";
import {
  DndContext,
  type DragEndEvent,
  type DragOverEvent,
  DragOverlay,
  type DragStartEvent,
  useSensor,
  useSensors,
  KeyboardSensor,
  Announcements,
  UniqueIdentifier,
  TouchSensor,
  MouseSensor,
} from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";
import { type Task, TaskCard } from "./TaskCard.tsx";
import type { Column } from "./BoardColumn.tsx";
import { hasDraggableData } from "./utils.ts";
import { coordinateGetter } from "./multipleContainersKeyboardPreset.ts";
import { rfpActions } from "../../features/rfp/rfpSlice.js"
import { AddRFPDialog } from "./AddRFP.jsx"

import { 
  useGetRfpsQuery, 
  useLazyGetRfpsQuery, 
} from '../../features/client/clientApiSlice.js';

import { useUpdateRfpMutation } from "../../features/rfp/rfpApi.js"

import { 
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
 } from "@material-tailwind/react";

import defaultCols from "./data/columns.tsx";


export type ColumnId = (typeof defaultCols)[number]["id"];



export function KanbanBoard() {

  // Accessing the dispatch function from react-redux
  const dispatch = useDispatch();

  // Accessing the rfp value from the Redux store
  const taskState = useSelector((state: RootState) => state.rfp);

  const [updateRfp, { isLoading: isUpdating, isError, isSuccess }] = useUpdateRfpMutation();

  // Event handler for the task card move
  const handleTaskCardMove = async (id, status) => {
    console.log("handleTaskCardMove was called...");
    console.log("id",id)
    console.log("status",status)
    try {
        const result = await updateRfp({id, status});
        console.log("result", result)
        dispatch(rfpActions.updateRfpStatus({id, status: status}))
    } catch (error) {
        console.log("Error updating RFP:",error);
    }
    console.log("handleTaskCardMove was called...DONE")
    
  };  

  const { 
    data: rfps, 
    isLoading: isRfpsLoading, 
    isFetching: isRfpsFetching,
    isSuccess: isRfpsSuccess,
    isError: isRfpsError,
    error: rfpsError
  } = useGetRfpsQuery('rfpRequests', {
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })


  const [columns, setColumns] = useState<Column[]>(defaultCols);
  const pickedUpTaskColumn = useRef<ColumnId | null>(null);
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
  
  if (isRfpsSuccess) {
    console.log("rfps", rfps)
  }
  const [tasks, setTasks] = useState<Task[]>([]);

  const [activeColumn, setActiveColumn] = useState<Column | null>(null);

  const [activeTask, setActiveTask] = useState<Task | null>(null);


  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: coordinateGetter,
    })
  );

  const removeTask = (id: number) => {
    const taskIndex = tasks.findIndex((task) => task.id === id);
    const newTasks = arrayMove(tasks, taskIndex, tasks.length - 1);
    setTasks(newTasks);
  }

  function getDraggingTaskData(taskId: UniqueIdentifier, columnId: ColumnId) {
    const tasksInColumn = tasks.filter((task) => task.status === columnId);
    const taskPosition = tasksInColumn.findIndex((task) => task.id === taskId);
    const column = columns.find((col) => col.id === columnId);
    return {
      tasksInColumn,
      taskPosition,
      column,
    };
  }

  const announcements: Announcements = {
    onDragStart({ active }) {
      if (!hasDraggableData(active)) return;
      if (active.data.current?.type === "Column") {
        const startColumnIdx = columnsId.findIndex((id) => id === active.id);
        const startColumn = columns[startColumnIdx];
        return `Picked up Column ${startColumn?.title} at position: ${
          startColumnIdx + 1
        } of ${columnsId.length}`;
      } else if (active.data.current?.type === "Task") {
        pickedUpTaskColumn.current = active.data.current.task.status;
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          active.id,
          pickedUpTaskColumn.current
        );
        return `Picked up Task ${
          active.data.current.task.description
        } at position: ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column?.title}`;
      }
    },
    onDragOver({ active, over }) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) {
        return;
      }

      if (
        active.data.current?.type === "Column" &&
        over.data.current?.type === "Column"
      ) {
        const overColumnIdx = columnsId.findIndex((id) => id === over.id);
        return `Column ${active.data.current.column.title} was moved over ${
          over.data.current.column.title
        } at position ${overColumnIdx + 1} of ${columnsId.length}`;
      } else if (
        active.data.current?.type === "Task" &&
        over.data.current?.type === "Task"
      ) {
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          over.id,
          over.data.current.task.status
        );
        if (over.data.current.task.status !== pickedUpTaskColumn.current) {
          return `Task ${
            active.data.current.task.description
          } was moved over column ${column?.title} in position ${
            taskPosition + 1
          } of ${tasksInColumn.length}`;
        }
        return `Task was moved over position ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column?.title}`;
      }
    },
    onDragEnd({ active, over }) {
      // after movement is completed, update the task in the database
      if (!hasDraggableData(active) || !hasDraggableData(over)) {
        pickedUpTaskColumn.current = null;
        return;
      }
      if (
        active.data.current?.type === "Column" &&
        over.data.current?.type === "Column"
      ) {
        const overColumnPosition = columnsId.findIndex((id) => id === over.id);

        return `Column ${
          active.data.current.column.title
        } was dropped into position ${overColumnPosition + 1} of ${
          columnsId.length
        }`;
      } else if (
        active.data.current?.type === "Task" &&
        over.data.current?.type === "Task"
      ) {
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          over.id,
          over.data.current.task.status
        );
        if (over.data.current.task.status !== pickedUpTaskColumn.current) {
          handleTaskCardMove(active.id, over.data.current.task.status)
          return `Task was dropped into column ${column?.title} in position ${
            taskPosition + 1
          } of ${tasksInColumn.length}`;
        }
        return `Task was dropped into position ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column?.title}`;
      }
      pickedUpTaskColumn.current = null;
    },
    onDragCancel({ active }) {
      pickedUpTaskColumn.current = null;
      if (!hasDraggableData(active)) return;
      return `Dragging ${active.data.current?.type} cancelled.`;
    },
  };

  function onDragStart(event: DragStartEvent) {
    if (!hasDraggableData(event.active)) return;
    const data = event.active.data.current;
    if (data?.type === "Column") {
      setActiveColumn(data.column);
      return;
    }

    if (data?.type === "Task") {
      setActiveTask(data.task);
      return;
    }
  }

  const handleDragStart = (event: DragStartEvent) => {
    //onDragStart(event);
    const { active } = event
    setActiveTask(tasks?.find(task => task.id === active.id))

  }

  function onDragEnd(event: DragEndEvent) {
    setActiveColumn(null);
    setActiveTask(null);

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (!hasDraggableData(active)) return;

    const activeData = active.data.current;

    if (activeId === overId) return;

    const isActiveAColumn = activeData?.type === "Column";
    if (!isActiveAColumn) return;

    setColumns((columns) => {
      const activeColumnIndex = columns.findIndex((col) => col.id === activeId);

      const overColumnIndex = columns.findIndex((col) => col.id === overId);

      return arrayMove(columns, activeColumnIndex, overColumnIndex);
    });
  }


  const handleDragEnd = (event: DragEndEvent) => {
    //onDragEnd(event);
    const { active, over } = event
    if (active.id !== over.id) {
      setTasks((tasks) => {
        const activeTaskIndex = tasks.findIndex((t) => t.id === active.id)
        const overTaskIndex = tasks.findIndex((t) => t.id === over.id)
        return arrayMove(tasks, activeTaskIndex, overTaskIndex)
      })
    }
  } 


  function onDragOver(event: DragOverEvent) {
    console.log("********* onDragOverEvent")
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    if (!hasDraggableData(active) || !hasDraggableData(over)) return;

    const activeData = active.data.current;
    const overData = over.data.current;

    const isActiveATask = activeData?.type === "Task";
    const isOverATask = overData?.type === "Task";

    if (!isActiveATask) return;

    // dropping a Task over another Task
    if (isActiveATask && isOverATask) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.index === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);
        const activeTask = tasks[activeIndex];
        const overTask = tasks[overIndex];
        if (
          activeTask &&
          overTask &&
          activeTask.status !== overTask.columnId
        ) {
          activeTask.status = overTask.columnId;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }

        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    const isOverAColumn = overData?.type === "Column";

    // dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      const activeId = active.id as string;
      const overId = over?.id as ColumnId;

      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const activeTask = tasks[activeIndex];
        
        if (activeTask) {
          // Create a new task object with updated status
          const updatedTask = { ...activeTask, status: overId };
          
          // Update the task in the API
          updateRfp({ id: activeTask.id, status: overId })
            .unwrap()
            .then(() => {
              console.log("Task updated successfully");
            })
            .catch((error) => {
              console.error("Failed to update task:", error);
              // You might want to revert the UI change here if the API call fails
            });

          // Return a new array with the updated task
          return [
            ...tasks.slice(0, activeIndex),
            updatedTask,
            ...tasks.slice(activeIndex + 1)
          ];
        }
        return tasks;
      });
    }


  }

  useEffect(() => {
    if (isRfpsSuccess && rfps) {
      setTasks(rfps);
    }
  }, [isRfpsSuccess, rfps]);

  if (isRfpsLoading || isRfpsFetching) {
    return <PulseLoader color="#123abc" size={20} />;
  } else if (isRfpsError) {
    return <ErrorsPage />;
  } else if (isRfpsSuccess) {
    console.log("get RFP data was successful!");
    console.log("data="+JSON.stringify(rfps));
    if (columns) {
      console.log("columns="+JSON.stringify(columns));
    }
    if (tasks) {
      console.log("tasks="+JSON.stringify(tasks));
    }

    return (
        <DefaultLayout>
        <Breadcrumb pageName="Request For Proposal Board" />
            <AddRFPDialog />
            <div className="w-full overflow-x-auto pb-4">
              <div className="inline-flex p-2" style={{ minWidth: 'max-content' }}>
              <DndContext
                accessibility={{
                  announcements,
                }}
                sensors={sensors}
                //onDragStart={onDragStart}
                onDragStart={handleDragStart}
                //onDragEnd={onDragEnd}
                onDragEnd={handleDragEnd}
                onDragOver={onDragOver}
              >
              <BoardContainer>
                  <SortableContext 
                      items={columnsId}
                      strategy={verticalListSortingStrategy}
                  >
                  {columns.map((col) => (
                      <BoardColumn
                        key={col.id}
                        column={col}
                        tasks={tasks?.filter((task) => task.status === col.id)}
                      />
                  ))}
                  </SortableContext>
              </BoardContainer>

              {"document" in window &&
                  createPortal(
                  <DragOverlay>
                      {activeColumn && (
                      <BoardColumn
                        isOverlay
                        column={activeColumn}
                        tasks={tasks.filter((task) => task.status === activeColumn.id)}
                      />
                      )}
                      {activeTask && <TaskCard task={activeTask} isOverlay />}
                  </DragOverlay>,
                  document.body
                  )}
              </DndContext>
            </div>
          </div>
        </DefaultLayout>    
    );
  }
}