import React, { useState, useEffect } from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import { NavbarDefault } from "./widgets/layout/navigationbar";

import routes from "./routes";

import Layout from "./components/Layout";

// Home related pages
import { HomePage } from "./pages/home/HomePage";
import TermsPage from "./pages/home/TermsPage"
import ReadMePage from "./pages/home/ReadMePage"

// Auth related pages
import { SignInPage } from "./pages/auth/SignInPage";
import { ActivationPage } from "./pages/auth/ActivationPage";
import UnauthorizedPage from "./pages/auth/UnauthorizedPage";
import ErrorsPage from "./pages/auth/ErrorPage";

// Admin related pages
import CompleteRegistration from "./pages/auth/admin/CompleteRegistration";
import AddUserForm from "./pages/auth/admin/AddUserForm";
import AddCompanyForm from "./pages/auth/admin/AddCompanyForm"
import { UsersTable } from "./pages/auth/admin/ViewUsers";
import { CompaniesTable } from "./pages/auth/admin/ViewCompanies"
import { ProgramManagersTable } from "./pages/auth/admin/ViewEmployees"
import AddProjectForm from "./pages/auth/admin/AddProjectForm"

import PageTitle from "./components/PageTitle.tsx";
import Calendar from "./pages/Calendar";
import Alerts from "./pages/uielements/Alerts";

// Dashboard related pages
import { Profile } from "./pages/dashboard/profile";
import Analytics from "./pages/dashboard/analytics";
import ProjectDetails from "./pages/dashboard/projectdetails";
import ProjectCards from "./pages/dashboard/projectcards";
import { KanbanBoard } from "./components/kanban/KanbanBoard.tsx";
import RequestForProposal from "./pages/dashboard/viewRFPs";
import Messages from './pages/uielements/Messages';

import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './constants/roles'
import AddEmployeeForm from './pages/auth/admin/AddEmployee';
import AddProjectStepForm from './pages/auth/admin/AddProjectStep';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import AddCalendarEntry from './pages/auth/admin/AddCalendarEvent';
import EditProjectStepForm from './pages/auth/admin/EditProjectStep';
import NewProjectRequest from './pages/dashboard/newprojectRequest'

function App() {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true)

  const preloader = document.getElementById('preloader')

  if (preloader) {
    setTimeout(() => {
      preloader.style.display = 'none'
      setLoading(false)
    }, 2000);
  }

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, [])

  return (
    !loading && (
      <>
        {!(pathname.includes('/settings') || pathname.includes('/auth') || pathname.includes('/forgot') ||
          pathname.includes('/calendar') || pathname.includes('/messages') || pathname.includes('/login') ||
          pathname.includes('/alerts') || pathname.includes('/dashboard') || pathname.includes('/profile')) && (
            <div className="sticky top-0 z-999 flex w-full"> 
              <NavbarDefault routes={routes} />
            </div>
          )
        }

        <Routes>
          <Route path='*' element={<ErrorsPage />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />

            {/* Public routes */}
            <Route path="login" element={<SignInPage />} />
            <Route path="/auth/activation/user/:hashId" element={<ActivationPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="readme" element={<ReadMePage />} />
            <Route path="terms" element={<TermsPage />} />

            {/* Protected Routes */}
            <Route element={<PersistLogin />}>

              <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
                <Route element={<Prefetch />}>
                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Client, ROLES.Vendor]} />}>
                    <Route path="dashboard">
                      <Route path="/dashboard/analytics" element={
                          <>
                          <PageTitle title="Analytics | XConnections" />
                          <Analytics />
                          </>
                        } />
                      <Route path="/dashboard/projectdetails/:project_id" element={
                          <>
                          <PageTitle title="Project Details | XConnections" />
                          <ProjectDetails />
                          </>
                        } />
                      <Route path="/dashboard/projects" element={
                          <>
                          <PageTitle title="Projects | XConnections" />
                          <ProjectCards />
                          </>
                        } />
                      <Route path="/dashboard/viewrfps" element={
                        <>
                        <PageTitle title="View RFPs | XConnections" />
                        <RequestForProposal />
                        </>
                          } errorElement={<UnauthorizedPage />} />  
                    </Route>
                  </Route>

                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Client]} />}>
                    <Route path="/dashboard/newRFP" element={
                          <>
                          <PageTitle title="RFP | XConnections" />
                          < NewProjectRequest />
                          </>
                          } errorElement={<UnauthorizedPage />} />
                    <Route path="/dashboard/proposalworkflow" element={
                      <>
                      <PageTitle title="Proposal Workflow | XConnections" />                          
                      < KanbanBoard />
                      </>
                      } errorElement={<UnauthorizedPage />} />
                  </Route>

                  <Route
                    path="/calendar"
                    element={
                      <>
                        <PageTitle title="Calendar | XConnections" />
                        <Calendar />
                      </>
                    }
                  />

                  <Route path="/profile" element={
                    <>
                    <PageTitle title="Profile | XConnections" />
                    <Profile />
                    </>
                    }                   
                  />

                  <Route
                    path="/dashboard/alerts"
                    element={
                      <>
                        <PageTitle title="Alerts | XConnections" />
                        <Alerts />
                      </>
                    }
                  />

                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                    <Route
                      path="/dashboard/messages"
                      element={
                        <>
                        <PageTitle title="Messages | XConnections" />
                        <Messages />
                        </>
                      }
                      errorElement={<ErrorsPage />}
                    />
                  </Route>

                  {/* Admin only routes */}
                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                    <Route path="auth">
                      <Route path="/auth/adduser" element={
                        <>
                        <PageTitle title="Add User | XConnections" />
                        <AddUserForm />
                        </>
                        } errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/addcompany" element={
                        <> <PageTitle title="Add Company | XConnections" /><AddCompanyForm /></>} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/viewusers" element={
                        <> <PageTitle title="View Users | XConnections" />< UsersTable /></>} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/viewcompanies" element={
                        <> <PageTitle title="View Companies | XConnections" />< CompaniesTable /></>} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/viewemployees" element={
                        <> <PageTitle title="View Employees | XConnections" />< ProgramManagersTable /></>} errorElement={<UnauthorizedPage />} />                      
                      <Route path="/auth/register/:id" element={<CompleteRegistration />} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/addproject" element={
                        <> <PageTitle title="Add Project | XConnections" /><AddProjectForm /></>} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/addemployee" element={
                        <> <PageTitle title="Add Employee | XConnections" /><AddEmployeeForm /></>} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/addprojectstep/:project_id" element={
                        <> <PageTitle title="Add Project Step | XConnections" /><AddProjectStepForm /></>} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/editprojectstep/:project_id/:step_id" element={
                        <> <PageTitle title="Edit Project Step | XConnections" /><EditProjectStepForm /></>} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/newmeeting" element={
                        <> <PageTitle title="Schedule Meeting | XConnections" /><AddCalendarEntry /></>} errorElement={<UnauthorizedPage />} />
                    </Route>
                  </Route>

                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </>
    )
  );
}

export default App;